import isValidUrl from './helpers/is-valid-url.js';
import getFallbackId from './helpers/get-fallback-id.js';
import getHost from './helpers/get-host.js';
import getPathId from './helpers/get-path-id.js';
import getQueryId from './helpers/get-query-id.js';
import getHashId from './helpers/get-hash-id.js';
import urlParser from './helpers/parse-url.js';
import getStrippedHost from './get-stripped-host.js';

/**
 * @module external-id
 */

/**
 * Takes an URL and returns an ID.
 * @param {string | URL} url
 * @returns {string}
 */
function getExternalId(url) {
	if (!url || url === '') {
		throw new Error('No URL provided');
	}

	const parsedUrl = urlParser(url);

	if (!parsedUrl || !isValidUrl(parsedUrl)) {
		throw new Error('Not a valid URL format for generating ID');
	}

	const { hostname, searchParams, pathname, hash, protocol } = parsedUrl;
	const host = getHost(hostname);
	let id = '';

	// Has query params, check for ID
	if (Array.from(searchParams.values()).length !== 0) {
		id = getQueryId(host, searchParams);
	}

	// Has hash, check for ID
	if (!id && hash) {
		id = getHashId(host, hash);
	}

	// Check for ID in path
	if (!id) {
		id = getPathId(host, pathname);
	}

	// Return the ID if we found one.
	if (id) {
		return id;
	}

	// Remove query params and hash if present.
	const cleanUrl = `${protocol}//${hostname}${pathname}`;

	// Use fallback ID when no ID is present in URL
	return getFallbackId(host, cleanUrl);
}

export { getExternalId, getStrippedHost };
