import { tlds } from '@hapi/tlds';
import getQueryId from './get-query-id.js';

/**
 * Ensures that the URL is valid (to us) for creating an ID.
 * @param {URL | {}} [urlData={}]
 * @returns {boolean}
 */
export default function isValidUrl(urlData = {}) {
	// Not an object
	if (!isObject(urlData) && !isUrlObject(urlData)) {
		return false;
	}

	// Ensures urlData is a valid URL object
	if (
		!('hostname' in urlData) ||
		!('pathname' in urlData) ||
		!('searchParams' in urlData) ||
		!('hash' in urlData)
	) {
		return false;
	}

	const { hash, hostname, pathname, searchParams } = urlData;

	// Some URLs starts this way:
	// https://www.godt.no/#!/artikkel/24359727/bocuse-d-or-europa-2018-norges-christian-andre-pettersen-tok-gull
	// http://events.vglive.no/#event=2908
	const whitelistedHashes = ['#!/artikkel', '#event='];
	if (hash && whitelistedHashes.some((wl) => hash.startsWith(wl))) {
		return true;
	}

	// verify we have query params at all
	if (Array.from(searchParams).length !== 0) {
		const hasQueryId = getQueryId(hostname, searchParams);
		// if an id can be found there, url is valid
		if (hasQueryId) {
			return true;
		}
	}

	// Not path present, we want articles not front pages
	if (!pathname || pathname === '/' || pathname === '//') {
		return false;
	}

	// Not a valid host
	if (!isValidHost(hostname.toLowerCase())) {
		return false;
	}

	// Valid URL
	return true;
}

/**
 * Inspects the hostname to validate the URL.
 * @param {string} host
 * @returns {boolean}
 */
function isValidHost(host) {
	if (!host) {
		return false;
	}

	// Starts with .
	if (host.startsWith('.')) {
		return false;
	}

	// Host is missing dot
	if (!host.includes('.')) {
		return false;
	}

	const tld = host.split('.').at(-1);

	// Not a valid TLD
	if (!tld || !tlds.has(tld)) {
		return false;
	}

	// Host is fine
	return true;
}

/**
 * Check if an object is a real object ({}) and not an array, function, new Number etc.
 * @param {unknown} o
 * @returns {boolean}
 */
function isObject(o) {
	return Object.prototype.toString.call(o) === '[object Object]';
}

/**
 * Check if an object is an URL object (URL{}) and not an array, function, new Number etc.
 * @param {unknown} o
 * @returns {boolean}
 */
function isUrlObject(o) {
	return Object.prototype.toString.call(o) === '[object URL]';
}
