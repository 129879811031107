import { IS_NUMERIC_ID, HASH_SPLIT, IS_SHORT_HASH_ID } from './regexp.js';

/**
 * Parses the query params to find the external article ID.
 * @param {string} host
 * @param {string} hash
 * @returns {string}
 */
export default function getHashId(host, hash) {
	let id = '';
	// Only let through supported hash IDs
	if (!hash.includes('/') && !hash.startsWith('#event=')) {
		return id;
	}

	// Look for candidates
	const candidates = hash.split(HASH_SPLIT).filter(findCandidates);

	// Only one candidate, clean it.
	if (candidates.length === 1 && candidates[0]) {
		id = cleanId(candidates[0]);
	}

	// Multiple candidates, decide on one and clean it.
	if (candidates.length > 1) {
		const pickedId = pickId(candidates);
		if (pickedId) {
			id = cleanId(pickedId);
		}
	}

	// Id found in hash, create ID from it.
	if (id) {
		return `${host}/${id}`;
	}

	return '';
}

/**
 * Check if every candidate looks like something that could be an ID.
 * @param {string} part
 * @returns {boolean}
 */
function findCandidates(part) {
	// Contains at least 5 numbers and possibly a dot.
	if (IS_NUMERIC_ID.test(part)) {
		return true;
	}

	if (IS_SHORT_HASH_ID.test(part)) {
		return true;
	}

	return false;
}

/**
 * Tries to clean up the IDs.
 * @param {string} id
 * @returns {string}
 */
function cleanId(id) {
	// Match the correct part of an ID candidate to clean it.
	const numericId = IS_NUMERIC_ID.exec(id);

	// Plain numeric IDs are OK
	if (numericId && numericId.length !== 0) {
		return numericId[0];
	}

	// The ID is already fine.
	return id;
}

/**
 * Picks the best ID from an array of ID candidates.
 * @param {string[]} candidates
 * @returns {string}
 */
function pickId(candidates) {
	// Be stupid and only pick the first ID.
	return candidates.shift() || '';
}
