import md5 from 'md5';
/**
 * Creates an ID we couldn't find one in the URL.
 *
 * Example:
 * http://www.vpn.no/kampreferat/2015/06/vif-rosenborg-1-2/ => vpn.no/d100ef4112c7dea498d8ed8cbe2f0053
 *
 */

/**
 * @param {string} host
 * @param {string} url
 * @returns {string}
 */
export default function getFallbackId(host, url) {
	return `${host}/${md5(url)}`;
}
