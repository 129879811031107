// List of SLDs we support
const SECOND_LEVEL_DOMAINS = [
	'.co.uk',
	// Well, not a SLDs but..
	'.blogspot.com',
	'.wordpress.com',
];

const DAGBLADET_SUBDOMAINS = [
	'lommelegen',
	'bergen',
	'borsen',
	'dinside',
	'oppskrift',
	'trondheim',
];

/**
 * Cleans the hostname so we only have domain and TLD, except for blogspot URLs.
 *
 * Example:
 * www.nrk.no => nrk.no
 * torildsmat.blogspot.com => torildsmat.blogspot.com
 * @param {string} [hostname='']
 * @returns {string}
 */
export default function getHost(hostname = '') {
	hostname = hostname.replace('www.', '');
	hostname = hostname.replace('stage.', '');
	hostname = hostname.replace('stage-beta.', '');
	const splitHost = hostname.split('.');
	if (splitHost.length === 1 || !splitHost[0]) {
		return '';
	}

	if (isDagbladetSubDomain(splitHost[0], hostname)) {
		if (hostname.includes('trondheim') || hostname.includes('bergen')) {
			// for trondheim and bergen we don't want to remove dagbladet from the url
			return `${splitHost[0]}.${splitHost[1]}.${splitHost[splitHost.length - 1]}`;
		}
		return `${splitHost[0]}.${splitHost[splitHost.length - 1]}`;
	}

	if (splitHost.length === 3) {
		if (isSLDHost(hostname)) {
			return hostname;
		}

		return `${splitHost[1]}.${splitHost[2]}`;
	}

	return hostname;
}

/**
 * @param {string | string[]} host
 * @returns {boolean}
 */
function isSLDHost(host) {
	return SECOND_LEVEL_DOMAINS.some((sld) => host.includes(sld));
}

/**
 * @param {string} domain
 * @param {string} host
 * @returns {boolean}
 */
function isDagbladetSubDomain(domain, host) {
	if (!host.includes('dagbladet')) {
		return false;
	}
	return DAGBLADET_SUBDOMAINS.includes(domain);
}
