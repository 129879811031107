const TRIM_EXTENSION = /\.[a-z]*$|\.php3$/;
const CLEAN_CHARS = /[_\-/]/g;
const IS_NUMERIC_ID = /([0-9]+(\.)?([0-9]{4,}))/;
const IS_ROUND_NUMBER = /^(?:[0-9]{1}[0]+)$/;
const IS_NUMERIC_TRAILING_CHAR_ID = /[0-9.]{5,}[a-z]{1}/;
const IS_NUMERIC_LEADING_CHAR_ID = /[a-z]{1}[0-9.]{5,}/;
const IS_HASH_ID = /[a-f0-9]{20,}/;
const IS_YEAR = /^(?:20\d{2})+$/;
const IS_VIDEO_ID = /([A-Za-z0-9_]){8,}/;
const HASH_SPLIT = /(\/|#event=){1}/;
const IS_SHORT_HASH_ID = /([0-9]{4})/;
const IS_LABRADOR_ID_FORMAT = /^\d{8,9}$/; // supports 100M lab ids

export {
	TRIM_EXTENSION,
	CLEAN_CHARS,
	IS_NUMERIC_ID,
	IS_ROUND_NUMBER,
	IS_NUMERIC_LEADING_CHAR_ID,
	IS_NUMERIC_TRAILING_CHAR_ID,
	IS_HASH_ID,
	IS_YEAR,
	IS_VIDEO_ID,
	HASH_SPLIT,
	IS_SHORT_HASH_ID,
	IS_LABRADOR_ID_FORMAT,
};
