/* eslint-disable @typescript-eslint/no-unused-vars */

// helper to make the standards based URL object
// behave as this library expects

/**
 * @param {string | URL} input
 * @returns {URL | false}
 */

export default function urlParser(input) {
	try {
		const url = new URL(input);
		// if not thown, URL was valid
		return url;
	} catch (_e) {
		return false;
	}
}
