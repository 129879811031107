import isValidUrl from './helpers/is-valid-url.js';
import getHost from './helpers/get-host.js';
import urlParser from './helpers/parse-url.js';

/**
 * Takes an URL and returns the stripped host
 * @param {string | URL} url
 * @returns {string}
 */
export default function getStrippedHost(url) {
	if (url === '') {
		throw new Error('No URL provided');
	}

	const parsedUrl = urlParser(url);

	if (!parsedUrl || !isValidUrl(parsedUrl)) {
		throw new Error('Not a valid URL format for generating ID');
	}

	const { hostname } = parsedUrl;
	const host = getHost(hostname);
	return host;
}
